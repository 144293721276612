/** utm is appended to href that links to doist.com or twist.com */
export type UTMProps = {
    utm_source?: string
    utm_medium?: string
    utm_campaign?: string
    utm_content?: string
}

export function appendUTM(
    url: string,
    {
        utm_source = 'doist',
        utm_medium = 'referral',
        utm_campaign = 'unset',
        utm_content,
    }: UTMProps = {},
) {
    try {
        const fullURL = new URL(url)
        const urlParams = new URLSearchParams(fullURL.search)

        if (utm_source) {
            urlParams.append('utm_source', utm_source)
        }

        urlParams.append('utm_medium', utm_medium)
        urlParams.append('utm_campaign', utm_campaign)

        if (utm_content) {
            urlParams.append('utm_content', utm_content)
        }

        fullURL.search = urlParams.toString()

        return fullURL.toString()
    } catch {
        return url
    }
}
