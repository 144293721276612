'use client'

import { useCallback, useContext } from 'react'

import { URLContext } from '@/utils/url-context'

export const CLOUDINARY_CLOUD_NAME = 'imagist'

const CLD_IMG_FETCH_URL = 'https://res.cloudinary.com/imagist/image/fetch'
const CLD_VID_FETCH_URL = 'https://res.cloudinary.com/imagist/video/fetch'
const CLD_FMT_AUTO = 'f_auto'
const CLD_FMT_AUTO_IMG = 'f_auto:image'
export const CLD_FMT_AUTO_VIDEO = 'f_auto:video'

export type QualityTypes =
    | 'auto'
    | 'auto:best'
    | 'auto:eco'
    | 'auto:good'
    | 'auto:low'
    | 'jpegmini'
    | 'jpegmini:best'
    | 'jpegmini:high'
    | 'jpegmini:medium'
    | number

type Options = {
    format?: string
    quality?: QualityTypes
    width?: number
}

type PosterOptions = Options & { startOffset?: number }

export function useImage() {
    const currentUrl = useContext(URLContext)

    const getImage = useCallback(
        (src: string, { quality = 'auto', format = CLD_FMT_AUTO, width }: Options = {}) => {
            const srcUrl = new URL(src, currentUrl)
            const transforms = [CLD_IMG_FETCH_URL, `/q_${quality}`, `,${format}`]
            if (width) {
                transforms.push(`,c_scale,w_${width}`)
            }
            transforms.push(`/${encodeURIComponent(srcUrl.toString())}`)
            return new URL(transforms.join(''))
        },
        [currentUrl],
    )

    return { getImage }
}

export function useVideo() {
    const currentUrl = useContext(URLContext)

    const getVideo = useCallback(
        (src: string, { quality = 'auto' }: Options = {}) => {
            const srcUrl = new URL(src, currentUrl)
            return new URL(
                [
                    CLD_VID_FETCH_URL,
                    `/q_${quality}`,
                    `/${CLD_FMT_AUTO}`,
                    `/${encodeURIComponent(srcUrl.toString())}`,
                ].join(''),
            )
        },
        [currentUrl],
    )

    const getPoster = useCallback(
        (src: string, { quality = 'auto', startOffset = 0 }: PosterOptions = {}) => {
            const srcUrl = new URL(src, currentUrl)
            return new URL(
                [
                    CLD_VID_FETCH_URL,
                    `/q_${quality}`,
                    `/${CLD_FMT_AUTO_IMG}`,
                    `/so_${startOffset}`,
                    `/${encodeURIComponent(srcUrl.toString())}`,
                ].join(''),
            )
        },
        [currentUrl],
    )

    return { getVideo, getPoster }
}
