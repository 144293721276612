import type { AllHTMLAttributes, CSSProperties } from 'react'

import cn from 'clsx'

import css from './Hairline.module.css'

export type HairlineProps = {
    bgColor?: string
    spacing?: 0 | 4 | 8 | 12 | 16 | 24 | 32 | 48 | 64 | 96 | 128 | 160 | 192 | 240
}

interface HairlineCustomProps extends CSSProperties {
    '--spacing': string
}

/**
 * Horizontal separator.
 */
export function Hairline({
    className,
    bgColor = 'var(--color-black-04)',
    style,
    spacing = 32,
    ...rest
}: HairlineProps & AllHTMLAttributes<HTMLHRElement>) {
    const customProps: HairlineCustomProps = {
        '--spacing': spacing === 0 ? '0px' : `var(--space-${spacing})`,
    }

    return (
        <hr
            className={cn(css.hairline, className)}
            style={{ backgroundColor: bgColor, ...customProps, ...style }}
            {...rest}
        />
    )
}
