import cn from 'clsx'

import { BaseText } from './Text'
import type { TextComponentProps } from '@/components/Text/Text'

import css from './BlockQuote.module.css'

type BlockQuoteSize = 'quote-lg' | 'quote-base'

export type BlockQuoteProps = TextComponentProps<BlockQuoteSize>

export function BlockQuote({
    tag = 'blockquote',
    size = 'quote-base',
    children,
    className,
    ...rest
}: BlockQuoteProps) {
    return (
        <BaseText
            className={cn(css.blockQuote, className)}
            cssRules={css}
            size={size}
            tag={tag}
            {...rest}
        >
            {children}
        </BaseText>
    )
}
