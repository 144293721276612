import { createContext, ReactNode, useContext } from 'react'

type Product = 'twist' | 'todoist' | 'doist'

type ProductProviderProps = {
    children: ReactNode
    product: Product
}

const ProductContext = createContext<{ product: Product }>({ product: 'twist' })

export function ProductProvider({ children, product }: ProductProviderProps) {
    return <ProductContext.Provider value={{ product }}>{children}</ProductContext.Provider>
}

export function useProduct() {
    const { product } = useContext(ProductContext)

    if (!product) {
        throw new Error('useProduct must be within a ProductProvider')
    }

    return product
}
