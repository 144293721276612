'use client'

import { type QualityTypes, useImage } from '@/utils/cloudinary'
import { useNextContext } from '@/utils/next-context'

type LoaderProps = {
    src: string
    width: number
}

export type BaseImageProps = Omit<
    JSX.IntrinsicElements['img'],
    'src' | 'srcSet' | 'ref' | 'width' | 'height' | 'loading' | 'quality'
> & {
    src: string
    priority?: boolean
    loading?: 'lazy' | 'eager' | undefined
    quality?: QualityTypes | number
    useResponsiveSizes?: boolean
    sizes?: string
}

export type ImageWithDimensions = {
    width: number
    height: number
    fill?: never
}

export type ImageWithFill = {
    width?: never
    height?: never
    fill: boolean
}

export type ImageProps = BaseImageProps & (ImageWithDimensions | ImageWithFill)

function canImageBeUsedWithCloudinaryLoader(src: string, allowedDomains: string[]) {
    if (src.startsWith('/')) return true
    if (src.endsWith('.gif')) return false
    if (src.startsWith('http')) {
        try {
            const url = new URL(src)
            return allowedDomains.some((domain) => url.hostname.endsWith(domain))
        } catch {
            return false
        }
    }
    return false
}

export function Image({
    width,
    quality = 'auto',
    useResponsiveSizes = true,
    sizes,
    src,
    ...props
}: ImageProps) {
    const {
        components: { head: Head, image: NextImage },
        cloudinaryAllowedDomains,
    } = useNextContext()
    const { getImage } = useImage()
    const shouldUseCloudinaryLoader =
        !(import.meta.env.STORYBOOK === 'true') &&
        process.env.APP_ENV !== 'development' &&
        process.env.NEXT_PUBLIC_APP_ENV !== 'development' &&
        canImageBeUsedWithCloudinaryLoader(src, cloudinaryAllowedDomains)

    function cloudinaryLoader({ src, width }: LoaderProps) {
        return getImage(src, { quality, width })
    }

    let imgSizes: string | undefined
    if (sizes) {
        imgSizes = sizes
    } else if (useResponsiveSizes) {
        imgSizes = `(max-width: ${width}px) 100vw, ${width}px`
    }

    return (
        <>
            <Head>
                <link
                    rel="preconnect"
                    href="https://res.cloudinary.com"
                    key="cloudinary-preconnect"
                />
            </Head>
            <NextImage
                loader={shouldUseCloudinaryLoader ? cloudinaryLoader : undefined}
                width={width}
                sizes={imgSizes}
                priority={props.priority}
                src={src}
                {...props}
            />
        </>
    )
}
